<template>
  <table id="datatables" class="table table-striped table-bordered">
    <thead>
      <tr>
        <th style="width: 220px;">
          날짜
        </th>
        <th>
          <div style="float: right">
            <div style="float: right">
              <span
                style="margin-left: 25px; margin-right: 7px; font-size: 12px; font-weight: 300"
                >최근 기간 /</span
              >
              <v-btn
                v-for="(item, n) in kwTargets.kw_targets"
                :key="n"
                class="period-button-style"
                @click="getApiData(item.target_no)"
              >
                {{ item.target_representative_name }}
              </v-btn>
              <v-btn class="period-button-style" @click="getApiData()"
                >전체</v-btn
              >
            </div>
            <div style="float: right">
              <span style="margin-right: 7px; font-size: 12px; font-weight: 300"
                >기간 검색 /</span
              >
              <span>
                <v-icon style="color: black" id="pikaday-start-icon"
                  >mdi-calendar-month</v-icon
                >
                <input
                  type="text"
                  id="datepicker-start"
                  :value="startDate"
                  readonly="readonly"
                  style="text-align: center; border: 1px solid black; border-radius: 5px; font-size: 12px"
                />
              </span>
              <span style="font-size: 14px; margin-left: 3px; margin-right: 7px"
                >부터</span
              >

              <span>
                <v-icon style="color: black" id="pikaday-end-icon"
                  >mdi-calendar-month-outline</v-icon
                >

                <input
                  type="text"
                  id="datepicker-end"
                  v-model="endDate"
                  readonly="readonly"
                  style="text-align: center; border: 1px solid black; border-radius: 5px; font-size: 12px"
                />
              </span>
              <span style="font-size: 14px; margin-left: 3px; margin-right: 7px"
                >까지</span
              >

              <v-btn
                class="period-button-style"
                @click="getApiData(startDate, endDate)"
                >검색</v-btn
              >
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody v-if="!loading">
      <tr v-for="(item, n) in apiData" :key="n">
        <td style="">
          {{
            item.createdDate === null
              ? ""
              : dateStringFormatting(item.createdDate, "yyyy-mm-dd", "-")
          }}
        </td>
        <td v-html="item.content"></td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="n in 5" :key="n">
        <td>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </td>
        <td>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import { dataSetCodeConverter } from "../../../mixins/dataSetCodeConverter";
import { kwTargets } from "../../../mixins/explore_period/kwTargets";

export default {
  name: "Datatable",
  mixins: [dataSetCodeConverter, kwTargets],
  props: {},
  data() {
    return {
      dataSet: this.$route.query.dataSet,
      query: this.$route.query.query,

      apiData: null,
      datatables: null,
      loading: true,

      startDate: "시작날짜",
      endDate: "종료날짜"
    };
  },
  mounted() {
    this.getApiData();

    // 아이콘 클릭시 달력이 출현하도록
    const pikadayStart = this.createPikaday("datepicker-start");
    const pikadayEnd = this.createPikaday("datepicker-end");
    document
      .getElementById("pikaday-start-icon")
      .addEventListener("click", function() {
        pikadayStart.show();
      });
    document
      .getElementById("pikaday-end-icon")
      .addEventListener("click", function() {
        pikadayEnd.show();
      });
    // 아이콘 클릭시 달력이 출현하도록 끝
  },
  methods: {
    createDatatables: function() {
      // eslint-disable-next-line no-undef
      $(document).ready(function() {
        // eslint-disable-next-line no-undef
        this.datatables = $("#datatables").DataTable({
          searching: false,
          ordering: false,
          lengthChange: false,
          info: false,
          oLanguage: {
            sSearch: "● 찾기 원하는 단어를 입력하세요."
          },
          pageLength: 6,
          language: {
            emptyTable: "검색결과가 없습니다."
          },
          columnDefs: [{ width: "15%", targets: 0 }]
        });
      });
    },
    dateStringFormatting: function(_date, _format, _delimiter) {
      const formatedDate = this.stringToDate(_date, _format, _delimiter);

      const month = formatedDate.getUTCMonth() + 1; //months from 1-12
      const day = formatedDate.getUTCDate();
      const year = formatedDate.getUTCFullYear();

      return `${year}/${month}/${day}`;
    },
    stringToDate: function(_date, _format, _delimiter) {
      const formatLowerCase = _format.toLowerCase();
      const formatItems = formatLowerCase.split(_delimiter);
      const dateItems = _date.split(_delimiter);
      const monthIndex = formatItems.indexOf("mm");
      const dayIndex = formatItems.indexOf("dd");
      const yearIndex = formatItems.indexOf("yyyy");
      let month_ = parseInt(dateItems[monthIndex]);
      month_ -= 1;
      const formatedDate = new Date(
        dateItems[yearIndex],
        month_,
        dateItems[dayIndex]
      );

      formatedDate.setDate(formatedDate.getDate() + 1);
      return formatedDate;
    },
    dateToString: function(date) {
      const month = String(date.getMonth() + 1).padStart(2, "0"); //months from 1-12
      const day = String(date.getDate()).padStart(2, "0");
      const year = String(date.getFullYear()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    subtractDate: function(days) {
      const targetDate = new Date();
      targetDate.setDate(targetDate.getDate() - days);

      return targetDate;
    },
    getApiData: async function(requestPeriodOrStartDate, endDate) {
      if (
        requestPeriodOrStartDate !== undefined &&
          endDate !== undefined &&
          this.stringToDate(requestPeriodOrStartDate, "yyyy-mm-dd", "-") >
            this.stringToDate(endDate, "yyyy-mm-dd", "-")
      ) {
        alert("기간을 다시 선택해주세요.");
        return;
      }

      this.loading = true;
      // eslint-disable-next-line no-undef
      $("#datatables")
        .DataTable()
        .destroy();
      // api 예제 const RequestUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/api/searchV2?dataSet=${this.krToEn(this.dataSet)}&terms=${this.secondCate}${encodeURIComponent("#")}${this.thirdCate}&fromDate=${startDate}&toDate=${endDate}`;

      const requestBaseUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${ process.env.VUE_APP_API_BASE_PORT }/api/searchV4?dataSet=${this.krToEn(this.dataSet)}&terms=${ this.query }`;
      console.log(this.query)
      const requestUrl =
        requestPeriodOrStartDate === undefined // 파라미터에 아무것도 없으면 전체 데이터 요청 (자바스크립트는 오버로딩이 안됨)
          ? requestBaseUrl
          : endDate === undefined
          ? `${requestBaseUrl}&targetNo=${requestPeriodOrStartDate}` // 2번째 파라미터가 없으면 1주 1달 3달 1년 이렇게 정해진 기간을 요청
          : `${requestBaseUrl}&fromDate=${requestPeriodOrStartDate}&toDate=${endDate}`; // 2번쨰 파라미터가 있으면 사용자정의 요청

      await axios
        .get(requestUrl, {
          headers: { Authorization: this.$store.getters.getLoginToken }
        })
        .then(res => {
          this.apiData = res.data;
          this.createDatatables();
        })
        .catch(error => {
          this.getApiData(); // 실패시 전체 데이터 요청
        });
      this.loading = false;
    },
    createPikaday: function(tagId) {
      // eslint-disable-next-line no-undef
      const picker = new Pikaday({
        field: document.getElementById(tagId),
        format: "YYYY-MM-DD",
        toString: (date, format) => {
          return this.dateToString(date);
        },
        onSelect: date => {
          tagId === "datepicker-start"
            ? (this.startDate = this.dateToString(date))
            : (this.endDate = this.dateToString(date));
        },
        i18n: {
          previousMonth: "이전달",
          nextMonth: "다음달",
          months: [
            "1월",
            "2월",
            "3월",
            "4월",
            "5월",
            "6월",
            "7월",
            "8월",
            "9월",
            "10월",
            "11월",
            "12월"
          ],
          weekdays: [
            "일요일",
            "월요일",
            "화요일",
            "수요일",
            "목요일",
            "금요일",
            "토요일"
          ],
          weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"]
        }
      });
      return picker;
    }
  }
};
</script>

<style scoped>
.period-button-style {
  height: 23px !important;
  background-color: lightgray;
  margin-right: 2px;
}
</style>

<template>
  <div id="content" style="min-width: 740px;position: relative; width: calc(100% - 285px); left: 256px; top: 100px; margin: 0px 15px; background-color: white; padding: 20px 15px; border-radius: 5px;box-shadow: 5px 4px 2px 0 rgb(60 75 100 / 14%), 0 3px 1px -2px rgb(60 75 100 / 12%), 0 1px 5px 0 rgb(60 75 100 / 20%);">
    <v-container style="max-width: none">
      <v-row>
        <v-col cols="12" style="padding: 0">
          <v-col cols="12">
            <v-card outlined tile style="padding: 6px;">
              <Datatable></Datatable>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import Datatable from "@/components/search/result/Datatable.vue";

export default {
  name: "Result",
  components: {
    Datatable
  }
};
</script>

<style scoped>

.search-container {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}
.search-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}
input[type="text"] {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}
input[type="text"]:hover, input[type="text"]:focus {
  border-color: #1a73e8; /* 파란색으로 변경 */
}
button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
button:hover {
  background-color: #0f57a1; /* 더 진한 파란색으로 변경 */
}

</style>
